@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
$primary: #25215e;
$secondary: #fec52e;
$white: #fff;
$black: #000;
$mediumGray: #4f4f4f;
$border: #e4e4e4;
$green: #3aa36a;
$error: #f16667;
$borderRadius: 8px;

:export {
  themecolor: $primary;
}
@mixin flexWrap(
  $align-items: center,
  $justify-content: flex-start,
  $flex-wrap: nowrap,
  $direction: row
) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-wrap: $flex-wrap;
  flex-direction: $direction;
}
* {
  word-wrap: break-word;
  word-break: break-word;
}

@import "~@coreui/coreui/scss/coreui.scss";

* {
  word-break: break-all;
  white-space: wrap;
}

iframe
// .order.fa-sort
{
  display: none !important;
}

body {
  background-color: white;
  font-family: "Poppins", sans-serif;
  color: $mediumGray;
}

img {
  font-size: 10px;
}

h2,
h3 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.navbarLogo {
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
    margin-left: 10px;
  }
}

.bellIcon {
  color: $black;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;

  span {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1;
    width: 16px;
    height: 16px;
    background-color: $primary;
    font-size: 12px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: 600;
  }
}

.text-nowrap {
  white-space: nowrap;
}

//button
.btn {
  height: 48px;
  font-size: 16px;
  font-weight: 500;

  &:focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
  }

  .h-auto {
    height: auto;
  }

  &-primary {
    background-color: $primary;
    border-color: $primary;
    padding: 0 20px;

    &:hover {
      background-color: rgba($color: $primary, $alpha: 0.9) !important;
    }

    &:focus {
      box-shadow: none;
      background-color: $primary;
      border-color: $primary;
    }

    &:active {
      box-shadow: none !important;
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }

  &-outline-primary {
    border: 1px solid $primary;
    background-color: $white;
    color: $primary;

    i {
      font-size: 16px;
    }

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  &-link {
    color: $primary;
    height: auto;
    padding: 2px 4px;
    &.remove {
      color: $error !important;
    }
  }
}

.note {
  margin-top: 10px;
  display: block;

  i {
    color: $error;
    margin-right: 10px;
  }
}

// form

input {
  &:not(.showarrow) {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  // login autofill background and color change
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
    box-shadow: 0 0 0 30px $white inset !important;
    -webkit-text-fill-color: $black;
    font-size: 16px;

    &::placeholder {
      color: $black;
    }

    ~ label {
      top: 2px;
    }
  }

  // input file hide
  &[type="file"] {
    display: none;
  }
  &.inputRangeCustom {
    padding: 0 !important;
  }
}
.input-group {
  background: $white;
  border-radius: 6px;
  border: 1px solid $border;

  input {
    border: none !important;
    border-radius: 0;
  }

  .input-group-text {
    width: 40px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-group-prepend {
    input,
    select {
      border: none !important;
    }
  }

  .input-group-append {
    input,
    select {
      border: none;
    }

    .input-group-text {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}

.custom-switch {
  .custom-control-input {
    &:focus {
      & ~ .custom-control-label {
        &::before {
          box-shadow: none;
        }
      }
    }

    &:checked {
      & ~ .custom-control-label {
        &::before {
          background-color: $primary;
          border-color: $primary;
        }
      }
    }
  }
}

.progress {
  width: 100%;
}

.form-error {
  display: block !important;
  color: $error;
  font-size: 14px;
  padding-left: 5px;
  margin: 3px 0 0;
}

.reactSelect {
  min-height: 48px;
  .css-13cymwt-control {
    border-color: #e4e4e4;
  }
  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: 48px;
    box-shadow: none;
    border-color: #e4e4e4 !important;
  }
  .css-1jqq78o-placeholder {
    color: rgba($color: $black, $alpha: 0.9);
  }
}

.form-control,
.form-control-file {
  color: $black !important;
  line-height: 1.4;
  height: 48px;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  letter-spacing: 0.3px;
  line-height: 1 !important;
  font-family: Poppins, sans-serif;
  border: 1px solid $border !important;
  background-color: white !important;

  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    background-color: #f6f6f6 !important;
    cursor: not-allowed;
  }
}

select,
option {
  text-transform: capitalize;
}

// card
.card {
  border-color: $border !important;
  border-radius: 10px !important;

  .card-header {
    background: none !important;
    padding: 0.5rem 0.8rem !important;

    .card-title {
      margin-bottom: 0 !important;
      color: $mediumGray;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent !important;
  }
}

// ====== Custom File Upload =======
.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    input {
      display: none;
    }

    .avatar {
      width: 80px !important;
      height: 80px !important;
      margin-bottom: 12px;
    }

    i.fa {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  }
}

.avatar-box {
  width: 90px !important;
  height: 90px !important;
  border: 1px solid $border !important;
  padding: 0px;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}

// loading
.loading-section {
  padding: 10px;
  text-align: center;
  width: 100%;
}

.ql-editor {
  height: 150px;
  background-color: #fff;
}

// date range
.DateRangePicker {
  // height: 50px;

  .DateRangePickerInput {
    background: $white !important;
    border-radius: 8px;
    // height: 100%;
    border-color: $border;
  }

  .DateInput_input {
    height: 100%;
    border-radius: 6px !important;
    background-color: transparent;
    font-size: 14px;
  }

  .DateInput {
    width: 120px !important;
    height: 45px;
    background: transparent !important;
  }
}

// login
.authWrapper {
  width: 100%;
  height: 100vh;
  @include flexWrap(center);
  .leftSide {
    flex: 0 0 50%;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-image: url("../assets//img/UH-60.jpg");
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .rightSide {
    flex: 1 1;
    height: 100vh;
    overflow: auto;
    @include flexWrap(center, center, nowrap, column);

    .loginWrapper {
      max-width: 450px;
      width: calc(100% - 20px);
      margin-top: auto;
      @include flexWrap(center, center, nowrap, column);

      .projectLogo {
        cursor: pointer;
        display: block;
        margin: 20px auto;
        height: 140px;
      }

      h4 {
        text-align: center;
        font-weight: 600;
        font-size: 26px;
        color: $primary;
        margin: 20px 0;
      }

      form {
        width: 100%;
      }
    }

    .forgotPassword {
      color: $primary;
      text-align: right;
      flex: 1 1;
      cursor: pointer;
      margin-top: 6px;
    }
  }
}

// footer
.loginFooter {
  color: #828282;
  width: 100%;
  margin-top: auto;
  padding: 0 30px 10px;
  .footerLogo {
    margin-top: 0;
  }
}

// pagination
.paginationWrap {
  .pagination {
    justify-content: flex-end;
    margin-bottom: 0;

    .page-item {
      .page-link {
        width: 28px;
        height: 28px;
        padding: 0;
        background-color: transparent;
        border-color: transparent;
        color: rgba($color: $black, $alpha: 0.5) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-size: 16px;

        span {
          font-size: 28px !important;
        }
      }

      &.active {
        .page-link {
          background-color: $primary;
          color: $white !important;
        }
      }
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

// teams
.userList [class^="col-"] {
  padding: 0 10px;
  margin-top: 20px;
}

.user_box {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.04);
  height: 100%;
  background-color: $white;
  padding-bottom: 20px;

  .user_image {
    position: relative;
    padding: 30px 30px 10px;

    .Image {
      height: 170px;
      width: 170px;
      border-radius: 50%;
      overflow: hidden;
      margin: auto;
      padding: 10px;
      border: 1px solid $border;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;

        &[src$=".svg"] {
          padding: 10px;
          object-fit: contain;
        }
      }
    }

    .switch_box {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    .switch_box .custom-control.custom-switch {
      height: 18px;
      min-width: 46px;
    }

    .switch_box .custom-switch .custom-control-label::before {
      height: 18px !important;
      width: 40px !important;
    }

    .switch_box .custom-switch .custom-control-label::after {
      width: 14px !important;
      height: 14px !important;
    }

    .custom-switch
      .custom-control-input:checked
      ~ .custom-control-label::after {
      transform: translateX(22px);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $primary;
    }
  }

  .user_content {
    padding: 10px 12px;
    text-align: center;
    font-size: 14px;

    h1 {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 10px;
      color: $primary;

      span {
        margin-left: 10px;

        i {
          font-size: 20px;
          margin-right: 10px;
          cursor: pointer;
          color: $primary;
        }
      }

      .detele {
        color: $error !important;
      }
    }

    ul {
      padding: 0;
      list-style: none;
      max-width: 70%;
      margin: auto;

      li {
        font-size: 16px;
        padding: 6px 0;
        color: #919191;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-all;

        &:last-child {
          border-color: transparent;
          padding-bottom: 0;
        }

        img {
          width: 20px;
        }

        i,
        img {
          margin-right: 10px;
        }
      }
    }
  }
}

.variables_textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  .dropdown-toggle {
    background: $black;
    color: $white;
    font-size: 14px;

    &:focus {
      background: $black;
      color: $white;
    }
  }

  .dropdown-menu {
    background: $white;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border: none;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);

    button {
      border: none !important;
      color: $black !important;

      &:focus {
        background: transparent !important;
        box-shadow: none !important;
        border: none !important;
        outline: none;
      }
    }
  }

  .btn-group {
    &.show {
      .dropdown-toggle {
        background: $black;
        color: $white;
      }
    }
  }
}

// attachment
.uplaodFiled {
  display: block;
  border: 1px solid #e3e3e3;
  width: 90px;
  min-width: 90px;
  height: 90px;
  border-radius: 4px;

  .uplaod_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;

    i {
      color: $mediumGray;
      font-size: 20px;
    }

    span {
      margin-top: 10px;
      font-weight: 400 !important;
    }
  }
}

.attachmentWrapper {
  .attachmentFile {
    margin-bottom: 0 !important;
    margin-right: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    width: 70px;
    height: 70px;
    border-radius: 4px;
    border: 1px solid $border;
    position: relative;

    i {
      color: $black;
      font-size: 24px;
    }

    i {
      &.pdf_file {
        color: $error;
      }
    }

    i {
      &.doc_file {
        color: $primary;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }

    .removeattachment {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 20px;
      height: 20px;
      margin-left: 6px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $error !important;
      box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      font-size: 10px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  span {
    width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
}

.attachment_list {
  overflow-x: auto;
  margin-left: 10px;
  padding: 10px 0 0;
}

// modal
.modal {
  .modal-footer {
    justify-content: center;

    & > :not(:last-child) {
      margin-right: 0.7rem;
    }

    button {
      padding: 8px 20px;
    }
  }
}

// checkbox
.custom-checkbox {
  display: flex;
  align-items: center;
}

.role_permission {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0;

  .custom-checkbox {
    margin-left: 10px;
    label {
      font-size: 14px;
    }

    label {
      margin: 0 !important;
    }
  }
}

.permissionCheckbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .custom-checkbox {
    display: flex;
    align-items: center;
    margin-right: 22px;
    margin-bottom: 15px;

    label {
      margin: 0 !important;
    }
  }
}

.InnerWrapper {
  background: #f3f3f3;
  overflow: auto;
  padding: 0 30px 40px;
}

.PgTitle {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  margin: 30px 0;
  @include flexWrap(center, space-between, nowrap);

  .pg-heading {
    color: #000;
    letter-spacing: 0.3px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
  }

  .right {
    @include flexWrap(center, flex-end, wrap);
    flex: 1;
    .btn {
      font-size: 12px;
      padding: 6px 8px;
      height: auto;
    }
  }

  button {
    white-space: nowrap;
    margin-left: 10px;
  }

  select {
    background-color: white !important;
  }
}

// dashboard
.innerContent_Wrapper {
  border-radius: 6px;
  padding: 20px;

  .serviceWrapper {
    h2 {
      text-align: center;
      display: block;
      font-weight: 600;
      font-size: 26px;
    }
  }
}

// nav tabs
.nav-pills {
  position: relative;
  z-index: 1;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 6px;

  &::after {
    border-bottom: 1px solid #eaf0fd;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: -1;
  }

  .nav-item {
    .nav-link {
      cursor: pointer;
      background: transparent;
      color: rgba($color: $black, $alpha: 0.7);
      font-size: 16px;
      border-radius: 0;
      font-weight: 500;

      &.active {
        color: $primary;
        border-bottom: 1px solid $primary;
      }
    }
  }
}

.vendorsWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .form-control {
    height: auto;
    width: 200px;
  }
}

.mapWrap {
  padding: 25px 0;

  .mapInfo {
    position: absolute;
    top: 400px;
    left: 20px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 6px 0;
        font-size: 14px;
        color: $black;

        span {
          width: 12px;
          height: 12px;
          outline: 1px solid $border;
          outline-offset: 2px;
          border-radius: 50px;
          display: inline-block;
          margin-right: 10px;

          &.green {
            background: $green;
          }

          &.blue {
            background: #2549ff;
          }

          &.orange {
            background: #ffa611;
          }

          &.red {
            background: $error;
          }
        }
      }

      .mapContainer {
        position: relative;
        width: 100%;
        .maptitle {
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 15px;
        }

        #map {
          width: 100%;
          #map_holder {
            width: 100% !important;
          }
        }
      }

      img {
        max-width: 600px;
        width: 100%;
      }
    }
  }

  .mapContainer {
    position: relative;
    width: 100%;
    .maptitle {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 15px;
    }

    #map {
      width: 100%;
      #map_holder {
        width: 100% !important;
      }
    }
  }

  img {
    max-width: 600px;
    width: 100%;
  }
}

.dashboard_card {
  border: none;
  padding: 20px;

  .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .CardInfo {
      h3 {
        color: $primary;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 12px;
      }

      span.Count {
        color: $primary;
        font-size: 45px !important;
        display: block;
        font-weight: 600;
      }

      p {
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 14px !important;
        color: $mediumGray;

        span {
          color: $green;
          margin-right: 5px !important;
          font-size: 15px !important;
          font-weight: 500;

          i {
            margin-right: 2px;
          }
        }
      }
    }

    .cardImage {
      width: 150px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// range slider
.RangeSlider {
  .rangeslider {
    margin: 10px 0 20px;
    box-shadow: none;

    .rangeslider__fill {
      background-color: $primary;
    }

    .rangeslider__handle {
      box-shadow: none;
      border-color: $primary;

      &::after {
        background-color: $primary;
      }

      .rangeslider__handle-tooltip {
        span {
          color: $white !important;
        }
      }
    }
  }
}

// table
.table-responsive {
  /* width */
  &::-webkit-scrollbar {
    height: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.noData {
  padding: 10px;
  margin: 20px auto;
  text-align: center;
  img {
    width: 40px;
  }
  p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 12px;
  }
}

.table {
  margin-bottom: 0;
  border: 1px solid transparent !important;
  overflow: hidden;
  padding: 10px !important;
  border-radius: 10px !important;

  tr {
    td:first-child,
    th:first-child {
      .custom-checkbox {
        .custom-control-label {
          margin-top: -16px;
        }
      }
    }
    td,
    th {
      .clicks {
        a {
          width: 30px;
          display: inline-flex;
          align-items: flex-end;
          justify-content: center;
          margin: 0 6px;

          i {
            font-size: 16px;
          }
        }
      }
    }
  }

  thead {
    th {
      border-bottom-width: 1px;
      font-size: 12px;
      color: $black;
      font-weight: 500;
      background: #fff;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      background: #fff;
      td {
        color: $mediumGray !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        vertical-align: middle;
        .clicks {
          display: flex;
          a {
            background-color: rgba($color: $black, $alpha: 0.06);
            margin: 0 6px;
            color: $white;
            align-items: center !important;
            width: 30px;
            height: 30px;
          }
        }
        a,
        .btn {
          font-size: 12px;
        }
        .btn-link {
          cursor: pointer;
          text-transform: capitalize;
          color: $primary;
          text-decoration: none !important;
          font-size: 12px;
        }
        select {
          border-radius: 4px !important;
          height: auto !important;
          max-width: 120px;
          min-width: 90px !important;
          background-color: #f4f7fc !important;
          font-size: 12px;
        }

        .action {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            padding: 6px;

            &.btn-delete {
              i {
                color: $error;
              }
            }

            &.btn-edit {
              i {
                color: $primary;
              }
            }

            i {
              font-size: 18px;
            }
          }
        }

        a,
        .link {
          color: #5491a9 !important;
        }

        .tableImg {
          display: flex;
          align-items: center;

          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            border: 1px solid rgba($color: $black, $alpha: 0.06);
          }

          span {
            white-space: pre-wrap;
          }
        }

        &.tableLink {
          color: $primary !important;
          cursor: pointer;
          white-space: normal;
        }
      }
    }
  }
}

.badge-warning {
  background-color: $secondary;
}

.poweredBy {
  text-align: center;
  padding-top: 4px;
  width: 100%;
  a {
    color: #31c5c7;
    text-decoration: none !important;
    margin-left: 3px;
  }
}

.app-footer {
  color: #5a607f;
  font-size: 16px;
  padding: 15px 30px;
  letter-spacing: 0.3px;
  background-color: white !important;
  border: none !important;
}

.footerLogo {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
  img {
    height: 40px;
    margin-right: 10px;
  }
  a {
    padding-right: 5px;
    line-height: 1.2;
    padding-left: 5px;
    + a {
      border-left: 1px solid rgba($color: $black, $alpha: 0.5);
    }
  }
}
.footerBottom {
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100%;
  .copyright {
    width: 100%;
  }
  .poweredBy {
    text-align: right;
  }
}

.SocialLinksFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    text-decoration: none !important;
    color: #555 !important;
    display: inline-block;
    margin-left: 10px;
  }
}

.modal-header,
.modal-footer {
  padding: 2rem;
  border: none;
}

.modal-title {
  font-size: 20px;
  color: $mediumGray;
}

.modal-body {
  color: $mediumGray;
}

.modal-header .close {
  padding: 0.5rem 1rem;
  margin: -0.2rem -1rem -1rem auto;
  font-size: 20px;
  outline: 0 !important;
}

.modal-header {
  padding: 1rem 1.25rem !important;
  border: none;
  letter-spacing: 0.5px !important;

  .modal-title {
    margin-bottom: 0 !important;
    color: $mediumGray !important;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }

  &.invoice-modal-header {
    .modal-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.searchHistory {
  table {
    tr {
      td {
        .countryName {
          border: 1px solid rgba($color: $black, $alpha: 0.7);
          display: inline-block;
          margin-right: 4px;
          padding: 6px;
          margin-bottom: 6px;
          color: $black;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            background-color: $black;
            color: $white;
          }
        }
      }
    }
  }
}

// header & side menu css
.navbar-nav .nav-link.active {
  color: $black;
  font-weight: 600;
}

.app-header {
  padding: 8px 10px !important;
  height: 70px !important;
  border: none !important;
  z-index: 99;

  &.navbar {
    top: 0;
  }

  .navbar-toggler {
    outline: 0 !important;
    min-width: auto;
  }

  .navbarLogo {
    .navbar-brand {
      width: unset !important;
      height: 60px;
      position: relative !important;
      margin: 0 !important;
      left: auto !important;

      img {
        object-position: left;
      }
    }
  }

  .header-center {
    width: 100% !important;
    justify-content: center;
    height: 100%;

    .nav-item {
      height: 100%;
      padding: 0 !important;

      .nav-link {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: rgba($color: $primary, $alpha: 0.8) !important;
        padding: 0 20px;
        font-weight: 500;

        &::after {
          content: "";
          position: absolute;
          bottom: -9px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $white;
        }

        &.active,
        &:hover {
          color: $primary !important;
          &::after {
            background-color: $primary;
          }
        }
      }
    }
  }

  .header-right {
    .nav-item {
      min-width: auto !important;

      .notification.active {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: -1px;
          right: 8px;
          width: 11px;
          height: 11px;
          border-radius: 100%;
          background: $danger !important;
        }
      }

      .nav-link {
        padding-left: 10px;
        padding-right: 0px;
        i {
          font-size: 14px !important;
        }
        .userName {
          white-space: nowrap;
          font-size: 12px;
        }
      }
    }

    .dropdown-toggle.nav-link {
      font-size: 16px !important;
      font-weight: 400 !important;
      display: flex;
      align-items: center;
    }

    .dropdown-item {
      color: black !important;
      font-size: 14px !important;
      outline: none !important;
      background-color: transparent !important;
      border-color: $border !important;
    }
  }
}

.avatar {
  width: 30px;
  height: 30px;
  border: 1px solid $border;
  border-radius: 100%;
  object-fit: cover;
}

// notification
.notificationList {
  ul {
    margin: 0;
    padding-left: 0px;
    list-style: none;
    border: 1px solid $border;
    border-radius: 10px;
    overflow: hidden;
    li {
      padding: 15px;
      padding-left: 35px;
      background-color: #ededed;
      cursor: pointer;
      border-bottom: 1px dashed $border;
      position: relative;
      .nofiticationHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 8px;
        h2 {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }
        span {
          font-size: 14px;
        }
      }

      p {
        font-size: 14px;
        margin: 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        border-radius: 50px;
        background-color: rgba($color: $black, $alpha: 0.4);
      }
      &.newMessage {
        background-color: $white;
        &::after {
          animation: circle-pulse 2s infinite;
          box-shadow: 0 0 0 0 #4daa57;
          background-color: $green;
        }
      }
    }
  }
}

@keyframes circle-pulse {
  0% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(77, 170, 87, 0.9);
    -webkit-box-shadow: 0 0 0 0 rgba(77, 170, 87, 0.9);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
    box-shadow: 0 0 0 8px rgba(77, 170, 87, 0);
    -webkit-box-shadow: 0 0 0 8px rgba(77, 170, 87, 0);
  }

  100% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(77, 170, 87, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(77, 170, 87, 0);
  }
}

// sidebar
.sidebar {
  background: $primary;
  top: 0;

  .nav {
    padding-top: 5px;
  }

  .nav-link {
    padding: 0.75rem;
  }

  .nav-link .nav-icon {
    color: #bbb;
    margin-right: 0.6rem;
  }

  .nav-link.active {
    background: $black;
  }

  .nav-link:hover {
    background: transparent;
  }

  .nav-link.active .nav-icon {
    color: #f5f5f5;
  }
}

// filter
.filterIcon {
  display: none;
}

.filterWrapper {
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: $white;
  @include flexWrap(center, flex-start, wrap);

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-right: 15px;
    margin-bottom: 0;
    border-right: 1px solid $black;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }

  .filterWarp {
    @include flexWrap(flex-end, flex-start, wrap);
    flex: 1;
    margin-left: 20px;
    padding-bottom: 10px;

    .form-group {
      margin-right: 15px;
      margin-top: 10px;
      margin-bottom: 0px;
      flex: 1 0 150px;
      max-width: 180px;
      flex-grow: 0;
      &.searchBox {
        max-width: 250px;
        flex: 1 0 200px;
      }
    }
    .reset {
      margin-left: auto;
    }
  }
  .btn-group {
    .btn {
      background: $white !important;
      border: 1px solid $border;
      height: 50px;
      margin: 0 !important;
      font-size: 14px !important;
      font-weight: 400;
      color: $mediumGray;
      padding: 10px 16px;
      height: auto;
      margin-right: -1px;

      &.active,
      &:hover {
        border: 1px solid rgba($color: $primary, $alpha: 0.6) !important;
        color: $primary !important;
      }
    }
  }

  .DateRangePicker {
    margin-left: 10px !important;
  }
}

.company-logo {
  width: 260px;
  margin: 15px auto 40px;
  display: block;
  cursor: pointer;
}

.login-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  max-width: 500px;
  margin: 15px auto 10px;
}

.login-footer * {
  letter-spacing: 0.3px;
  font-size: 14px;
}

.dashboardCard-Graph {
  .card-header {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $mediumGray;
    font-size: 16px;
    min-height: 80px;
    line-height: 1;
    background-color: #ffffff;
  }
}

.dashboardCard-Main {
  .card-body {
    color: $primary;
    font-size: 25px;
    padding: 2rem 0.75rem 0.8rem;
  }

  .card-header {
    position: relative;
    padding-bottom: 1rem;

    .bgIcon {
      position: absolute;
      right: -12px;
      top: -12px;
      color: rgba(95, 66, 170, 0.75);

      img {
        height: 67px;
        margin-top: 0;
      }

      i {
        font-size: 65px;
        margin-top: 0;
        color: $primary !important;
      }
    }
  }
}

.app-header .navbar-brand {
  img {
    height: 100%;
    object-fit: contain;
  }
}

.uploadFile {
  width: 150px;
  height: 150px;
  margin: 0 auto 40px;
  position: relative;

  label {
    border-radius: 50%;
    width: 100%;
    overflow: hidden;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .editIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: $white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba($color: $black, $alpha: 0.2);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
}

.uploadProfile {
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 108px;
  height: 108px;
  border-radius: 6px;
  display: block;
  margin: 0 auto 25px !important;
  position: relative;

  img {
    height: 108px;
    width: 108px;
    border-radius: 4px;
    object-fit: cover;
  }

  i {
    font-size: 14px;
    color: #999;
    width: 30px;
    height: 30px;
    line-height: 28px;
    position: absolute;
    bottom: -13px;
    left: 38px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 6px;
  }
}

.react-bs-table-pagination {
  font-size: 14px !important;
  margin: 15px 0 !important;

  .col-md-6 p {
    color: #000 !important;
    margin: 10px 0 0;
  }
}

.pagination {
  align-items: center;
  margin: 0;

  li {
    a {
      font-size: 14px;
      color: $mediumGray !important;
      width: 30px !important;
      height: 30px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
    }

    &.selected {
      a {
        border: 1px solid $primary;
        background-color: $primary;
        color: $white !important;
      }
    }

    &:first-child,
    &:last-child {
      a {
        font-size: 22px;
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }
}

/*-------------------------------------------------------*/
header.app-header.navbar i.icon-power {
  color: #000 !important;
}

// provider list
.serviceList {
  h2 {
    font-size: 16px;
    font-weight: 500;
    color: $black;
  }

  ul {
    background-color: #f1f1f1;
    padding: 16px;
    border-radius: 8px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    li {
      list-style: none;
      color: $black;
      border: 1px solid rgba($color: $black, $alpha: 0.5);
      padding: 6px 10px;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }
}

// extra code
.customTooltipItemBtn {
  color: $primary !important;
  text-decoration: none !important;
}

.tooltip-inner {
  color: white !important;
  font-weight: 700;
  background-color: $primary !important;
}

.tooltip .arrow::before {
  border-right-color: $primary !important;
}

.accordianWrap {
  border: 1px solid #d4ddee;
  margin-bottom: 0px;
  margin-top: 20px;
  .card-header {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.open {
    .card-header {
      span {
        transform: rotate(180deg);
      }
    }
  }
  .card-body {
    border-top: 1px solid #d4ddee;
    padding: 10px;
  }
}

.notes-list {
  margin-top: 20px;
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
  overflow: auto;
  max-height: 400px;
  .notesItem {
    border: 1px dashed $border;
    padding-bottom: 15px;
    margin-bottom: 15px;
    .notesInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border;
      padding: 8px;

      h3 {
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
    .notes-Content {
      padding: 8px;
      padding-bottom: 0;

      p {
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
  }
}

// upload progress css
#mediaQueueViewerWrapper {
  position: fixed;
  right: 25px;
  bottom: 60px;
  z-index: 999;
  height: 50px;
  background-color: $primary;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $borderRadius;

  #uploadeProgressHeader {
    color: $white;
    font-size: 14px;
  }
}


// media styles

@media screen and (min-width: 992px) {
  .header-fixed .app-body {
    margin-top: 70px;
  }
}

@media screen and (max-width: 1199px) {
  .navbarLogo {
    a {
      width: unset !important;
    }
    span {
      display: none;
    }
  }

  .header-right {
    .nav-item {
      &:not(.logout) {
        display: none;
      }
    }
  }
}

@media all and (max-width: 767.98px) {
  .loginPgBg .card-group {
    padding: 8px 5px 10px;
  }

  .loginPgBg h2 {
    font-size: 21px;
  }

  .mapWrap {
    .mapInfo {
      position: unset !important;
      ul {
        max-width: max-content;
        margin: 0 auto;
      }
    }
  }
  .footerBottom {
    flex-wrap: wrap;
    text-align: center;
    .poweredBy {
      text-align: center;
    }
  }
}

@media (min-width: 1200px) {
  .app-header.navbar {
    flex-wrap: nowrap !important;
  }

  .container {
    max-width: 100% !important;
  }
}

// 63

@media (max-width: 991px) {
  .authWrapper {
    .leftSide {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
    .rightSide {
      .loginWrapper {
        background-color: rgba($color: $white, $alpha: 0.8);
        padding: 20px;
        border-radius: 8px;
        .projectLogo {
          height: 120px;
          margin-bottom: 10px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
    .loginFooter {
      background-color: rgba($color: $white, $alpha: 0.8);
      padding-top: 20px;
      .footerBottom {
        font-size: 12px;
      }
    }
  }

  .filterIcon {
    display: block;
  }
  .filterWrapper {
    display: none;
    &.showFilter {
      display: block;
    }
    h3 {
      display: block;
      border: none;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
    }
    .filterWarp {
      margin-left: 0;
      .reset {
        margin-left: 15px;
      }
      .btn-group {
        margin-top: 15px;
      }
    }
  }

  // .DateRangePicker {
  //   width: 100% !important;
  // }
  .react-bs-container-body .table,
  .react-bs-container-header .table {
    width: 300%;
  }
}

@media screen and (max-width: 767.98px) {
  .InnerWrapper {
    padding: 0 15px 40px;
  }
  .app-header {
    padding: 8px 15px !important;
  }
  .filterWrapper {
    .filterWarp {
      margin-left: 0;
      .form-group {
        flex: 1 0 45%;
        max-width: unset;
        &.searchBox {
          flex: 1 0 45%;
          max-width: unset;
        }
      }
      .btn-group {
        margin-bottom: 10px !important;
        flex-wrap: wrap;
      }
      .reset {
        margin-top: 10px;
      }
    }
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 97%;
  }
}
@media screen and (max-width: 575.98%) {
  .filterWrapper {
    .filterWarp {
      .form-group {
        flex: 1 0 100%;
        max-width: unset;
      }
    }
  }
}

// 2456
